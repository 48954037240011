import React, { useEffect, useState } from "react";

const DateBlock = () => {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const displayGMTTime = () => {
    // Get the current date and time in GMT
    const now = new Date();

    // Get UTC time with leading zeros for minutes
    const hours = now.getUTCHours();
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const utcTime = `${hours}:${minutes}`;
    setTime(utcTime);

    // Get UTC date
    const utcDate = now.toLocaleString("en", {
      month: "short",
      day: "2-digit"
    });
    setDate(utcDate.toUpperCase());
  };

  useEffect(() => {
    // Call the function to display the GMT time initially
    displayGMTTime();

    // Update the time every minute (60000 milliseconds)
    const intervalId = setInterval(displayGMTTime, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="left_column">
      <div className="block time_zone" id="block_2861923">
        <div className="side_box">
          <div className="header">
            <div className="left_image"></div>
            <div className="right_image"></div>
            <div className="mid_image">
              <div></div>
            </div>
            <div className="inner_content">
              <div className="sh_padding">Time Zone</div>
            </div>
          </div>
          <div className="content">
            <div className="left_image"></div>
            <div className="right_image"></div>
            <div className="mid_image">
              <div></div>
            </div>
            <div className="inner_content">
              <div className="sc_padding">
                <div className="message">
                  <div className="time_block tztime_time" id="time_block_2861923">
                    {time}
                  </div>
                  <div className="tztime_date">
                    <div className="date_block" id="date_block_2861923">{date}</div>
                    <div className="zone_block" id="zone_block_2861923">
                      GMT
                    </div>
                  </div>

                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="left_image"></div>
            <div className="right_image"></div>
            <div className="mid_image">
              <div></div>
            </div>
            <div className="inner_content"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateBlock;
