import React from "react";
import * as Components from "../../components/index.js";
import {Link} from "react-router-dom";
import * as Pages from "../../pages/index.js";

const UnAuthorized = () => {

    return (
        <div id="dialog_container">
            <div id="dialog">
                <div id="guild_content">
                    <div className="box">
                        <div className="header">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bh_padding">
                                    Not Authorized
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bc_padding">

                                    <div className="message">
                                        <p>
                                            You do not have permission to perform this action.
                                        </p>
                                        <p>
                                            You need to login as Admin first. <Link href={Pages.Login}
                                                                                    to={"/login"}>Login now</Link>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bf_padding">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default UnAuthorized;