import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import './Planner.css';

const RecurringEventsCalendar = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const calculateRecurringEvents = () => {
            const recurringEvents = [];

            const currentDate = new Date();
            const endDate = new Date();
            endDate.setDate(currentDate.getDate() + 28); // Calculate 4 weeks ahead

            // Calculate the next occurrence of Monday, Sunday, Thursday, Friday, and Wednesday for the next 3 weeks
            while (currentDate <= endDate) {
                if (currentDate.getDay() === 1) {
                    recurringEvents.push({
                        title: '19:00ST ICC10',
                        start: currentDate.toISOString().slice(0, 10),
                    });
                } else if (currentDate.getDay() === 0) {
                    recurringEvents.push({
                        title: '18:30ST RS25&ICC25',
                        start: currentDate.toISOString().slice(0, 10),
                    });
                } else if (currentDate.getDay() === 4) {
                    recurringEvents.push({
                        title: '19:00ST NAXX10',
                        start: currentDate.toISOString().slice(0, 10),
                    });
                } else if (currentDate.getDay() === 5) {
                    recurringEvents.push({
                        title: '19:00ST ICC10 ALT',
                        start: currentDate.toISOString().slice(0, 10),
                    });
                } else if (currentDate.getDay() === 3) {
                    recurringEvents.push({
                        title: '19:00ST RS25 ALT',
                        start: currentDate.toISOString().slice(0, 10),
                    });
                }

                currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
            }

            return recurringEvents;
        };

        const recurringEvents = calculateRecurringEvents();
        setEvents(recurringEvents);
    }, []);

    return (
        <div className="calendar-container">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={events}
                themeSystem="bootstrap"
                headerToolbar={{
                    left: '',
                    center: 'title',
                    right: 'prev,next today',
                }}
                dayMaxEventRows={true}
                eventColor="rgba(0, 0, 255, 0.5)" // Neon transparent dark blue
                eventBorderColor="#3C97CA" // Border color
                eventTextColor="#3C97CA" // Font color
                titleFormat={{
                    month: 'long',
                    year: 'numeric',
                    // day: 'numeric',
                    separator: ' ',
                }}
                firstDay={1} // Start the week on Monday
                contentHeight="700px"
            />
        </div>
    );
};

export default RecurringEventsCalendar;
