import React, { useEffect, useState } from "react";
import { AddonFrame } from "../../components/index.js";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Addons.css";

// Static imports for images
import dk from "../../assets/class_images/dk.png";
import druid from "../../assets/class_images/druid.png";
import hunter from "../../assets/class_images/hunter.png";
import mage from "../../assets/class_images/mage.png";
import priest from "../../assets/class_images/priest.png";
import rogue from "../../assets/class_images/rogue.png";
import shaman from "../../assets/class_images/shaman.png";
import warlock from "../../assets/class_images/warlock.png";
import warrior from "../../assets/class_images/warrior.png";
import paladin from "../../assets/class_images/paladin.png";
import icc from "../../assets/class_images/icc.png";
import weakaura from "../../assets/class_images/weakaura.png";

const classImages = {
    "ICC MANDATORY": icc,
    "WA STRING IMPORTS": weakaura,
    "DEATH KNIGHT": dk,
    "DRUID": druid,
    "HUNTER": hunter,
    "MAGE": mage,
    "PALADIN": paladin,
    "PRIEST": priest,
    "ROGUE": rogue,
    "SHAMAN": shaman,
    "WARLOCK": warlock,
    "WARRIOR": warrior,
};

const Addons = () => {
    const [addons, setAddons] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const config = {
            method: 'post',
            url: '/pub/addons',
        };

        axios.request(config)
            .then((response) => {
                setAddons(response.data.addons);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const renderAddonFrames = () => {
        if (!addons) return null;

        return Object.keys(addons).map((key, index) => {
            const data = addons[key];
            if (Array.isArray(data) && data.length === 2) {
                const [description, datetime] = data;
                return (
                    <div key={key}>
                        <AddonFrame
                            title={`${key} Useful Addons`}
                            content={description}
                        />
                    </div>
                );
            } else {
                console.error(`Unexpected data format for ${key}: `, data);
                return null;
            }
        });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        customPaging: (i) => {
            const className = Object.keys(addons)[i];
            const imageSrc = classImages[className] || dk; // Default to dk if class not found
            const isActive = i === activeIndex;

            return (
                <button
                    style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        backgroundColor: isActive ? "#007bff" : "transparent",
                        border: "none",
                        padding: 0,
                        position: "relative",
                    }}
                    onClick={() => setActiveIndex(i)}
                >
                    <img
                        src={imageSrc}
                        alt={className}
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            opacity: isActive ? 1 : 0.5,
                        }}
                    />
                </button>
            );
        },
        appendDots: (dots) => (
            <div
                className="custom-dots-container"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 0,
                }}
            >
                {dots}
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
        beforeChange: (current, next) => setActiveIndex(next),
    };

    return (
        <div>
            <Slider {...settings}>
                {renderAddonFrames()}
            </Slider>
        </div>
    );
};

export default Addons;
