import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";


const Login = () => {
    const navigate = useNavigate();
    const checkbox_style = {
        scale: "1.6", outline: "none",
    }
    const login_custom_style = {
        width: "21%", marginLeft: "40%",
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let username = e.target[0].value;
        let password = e.target[1].value;
        let remember = e.target[2].checked;

        let auth = btoa(username + ":" + password + ":" + remember);
        let config = {
            method: 'post', maxBodyLength: Infinity, url: '/admin/login', headers: {
                'Authorization': "Basic " + auth
            },
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200 && response.data.message === "Logged in!") {
                    navigate("/admin")
                } else {
                    alert("wrong username or password");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (<div id="dialog_container">
        <div id="dialog">
            <div id="guild_content">
                <div className="box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bh_padding">
                                Sign in
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bc_padding">

                                <div className="message">

                                    <form className="simple_form new_user" id="login_form" onSubmit={handleSubmit}
                                          acceptCharset="UTF-8" style={{outline: "none"}}>

                                        <div className="control-group required user_email"><label
                                            className="required control-label"
                                            htmlFor="user_email">Username</label>
                                            <div className="controls"><input className="string username required"
                                                                             type="text"/>
                                            </div>
                                        </div>
                                        <div className="control-group password required user_password"><label
                                            className="password required control-label"
                                            htmlFor="user_password">Password</label>
                                            <div className="controls"><input className="password required"
                                                                             type="password"/>
                                            </div>
                                        </div>
                                        <div className="control-group boolean optional user_remember_me">
                                            <div className="controls"><label
                                                className="checkbox"><input className="boolean optional"
                                                                            type="checkbox"
                                                                            style={checkbox_style}
                                                                            value="1"
                                                                            id="user_remember_me"/> Remember
                                                me?</label>
                                            </div>
                                        </div>

                                        <div className="form-actions">
                                            <input type="submit" name="commit" value="Login" className="btn-primary"
                                                   data-disable-with="Login" style={login_custom_style}/>
                                        </div>
                                        <div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bf_padding">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>);
}

export default Login;