import React, { useEffect, useState } from "react";
import axios from "axios";

// Import images
import dk from "../../assets/class_images/dk.png";
import druid from "../../assets/class_images/druid.png";
import hunter from "../../assets/class_images/hunter.png";
import mage from "../../assets/class_images/mage.png";
import paladin from "../../assets/class_images/paladin.png";
import priest from "../../assets/class_images/priest.png";
import rogue from "../../assets/class_images/rogue.png";
import shaman from "../../assets/class_images/shaman.png";
import warlock from "../../assets/class_images/warlock.png";
import warrior from "../../assets/class_images/warrior.png";

const classes = [
    { name: "Death Knight", img: dk },
    { name: "Druid", img: druid },
    { name: "Hunter", img: hunter },
    { name: "Mage", img: mage },
    { name: "Paladin", img: paladin },
    { name: "Priest", img: priest },
    { name: "Rogue", img: rogue },
    { name: "Shaman", img: shaman },
    { name: "Warlock", img: warlock },
    { name: "Warrior", img: warrior },
];

const RecruitmentPanel = () => {
    const [recruitment, setRecruitment] = useState([]);
    const [discordLink, setDiscordLink] = useState("");

    useEffect(() => {
        axios.post('/pub/recruitment')
            .then(response => setRecruitment(response.data))
            .catch(error => console.log(error));

        axios.post('/pub/discord')
            .then(response => setDiscordLink(response.data.discord_link))
            .catch(error => console.log(error));
    }, []);

    return (
        <div id="left_column">
            <div className="block recruitment" id="block_2861924">
                <div className="side_box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image"><div></div></div>
                        <div className="inner_content">
                            <div className="sh_padding">Recruitment</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image"><div></div></div>
                        <div className="inner_content">
                            <div className="sc_padding">
                                <div className="message">
                                    <div className="mar_bottom"><strong>We are looking to recruit the following</strong></div>
                                    {recruitment.map((rec, index) => (
                                        <div key={classes[index].name} className="recruit">
                                            <small className="recruit-status other_text">{rec.status}</small>
                                            <div className="recruit-type">
                                                <img src={classes[index].img} alt={`Image of a ${classes[index].name}`} />
                                                {classes[index].name}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="mar_top">
                                        <a className="btn" target="_blank" rel="noopener noreferrer" href={discordLink} style={{ outline: "none" }}>Apply</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image"><div></div></div>
                        <div className="inner_content"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruitmentPanel;