import {Link, useLocation} from 'react-router-dom';
import {useEffect, useRef} from "react";
import React from "react";

const Navbar = () => {
    const path = useLocation().pathname.trim().split("/")[1];
    const linksRef = useRef([]);
    const style_override = {
        top: "8px", right: "5px", position: "relative", outline: "none",
    }

    useEffect(() => {
        // Clear previous styles
        linksRef.current.forEach(link => {
            if (link) link.style.color = 'white';
        });

        // Update style for active link
        const activeLink = linksRef.current.find(link => link.getAttribute('href') === location.pathname);
        if (activeLink) activeLink.style.color = 'green';
    }, [location.pathname]);


    return (<div className="menu_bar" id="main_menu">
        <div className="left_image"></div>
        <div className="right_image"></div>
        <div className="mid_image">
            <div></div>
        </div>
        <div className="inner_content">
            <div className="m_padding">
                <nav>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/"} ref={el => linksRef.current[0] = el}
                                  style={style_override}>Home</Link>
                        </div>
                    </div>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/tactics"} ref={el => linksRef.current[1] = el}
                                  style={style_override}>Assignments</Link>
                        </div>
                    </div>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/drama"} ref={el => linksRef.current[2] = el}
                                  style={style_override}>Drama</Link>
                        </div>
                    </div>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/events"} ref={el => linksRef.current[3] = el}
                                  style={style_override}>Events</Link>
                        </div>
                    </div>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/addons"} ref={el => linksRef.current[4] = el}
                                  style={style_override}>Addons</Link>
                        </div>
                    </div>
                    <div className="menu_item">
                        <div className="menu_link">
                            <Link to={"/admin"} ref={el => linksRef.current[5] = el}
                                  style={style_override}>Admin</Link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>);
}

export default Navbar;