import React, {useState} from 'react';

const AddonEdit = () => {

    return (

        <div id="dialog">
            <div id="guild_content">
                <div className="box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bh_padding">
                                Edit Addons
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bc_padding">
                                <div className="message">
                                    TODO: Next update lulw
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bf_padding">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default AddonEdit;