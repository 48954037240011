import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import * as Pages from './pages';
import * as Components from './components';

const App = () => {
    return (
        <Router>
            <Components.TopBanner/>
            <Components.NavBar/>
            <Routes>
                <Route path="/" element={<Pages.Home/>}/>
                <Route path="/admin" element={<Pages.Admin/>}/>
                <Route path="/addons" element={<Pages.Addons/>}/>
                <Route path="/drama" element={<Pages.Drama/>}/>
                <Route path="/tactics" element={<Pages.Tactics/>}/>
                <Route path="/events" element={<Pages.Events/>}/>
                <Route path="/login" element={<Pages.Login/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </Router>
    );
}

export default App;
