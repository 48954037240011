import React from "react";
import {redirect} from "react-router-dom";

const TopBanner = () => {
    const style_override = {
        height: "275px",
    }

    const home = () => {
        redirect('/');
    }

    return (
        <div id="banner" style={style_override} onClick={home}>
        
        </div>
    );
}

export default TopBanner;