import React from 'react';
import './ComingSoon.css'; // Import CSS for styling

const ComingSoon = () => {

    return (<div id="dialog_container">
        <div id="dialog">
            <div className="stars" aria-hidden="true"></div>
            <div className="stars2" aria-hidden="true"></div>
            <div className="stars3" aria-hidden="true"></div>
            <main className="main">

                <section className="contact">
                    <h1 className="title">Assembling the figgots</h1>
                    <h2 className="sub-title">Heroes and legends arise, as Northrend's icy whispers bind us all, forging unity in the battle against The Lich King's endless night.</h2>

                </section>
            </main>
        </div>
    </div>);
};

export default ComingSoon;
