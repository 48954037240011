import React from "react";
import GaugeComponent from "react-gauge-component";

const FiggotMeter = () => {

    return (
        <div id="dialog">
            <div id="guild_content">
                <div className="box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bh_padding">
                                Figgot meter
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bc_padding">
                                <div className="message">
                                    <GaugeComponent style={{maxHeight: "390px"}}
                                                    value={Math.floor(Math.random() * 100)}
                                                    type="radial"
                                                    labels={{
                                                        tickLabels: {
                                                            type: "inner",
                                                            ticks: [{value: 20}, {value: 40}, {value: 60}, {value: 80}, {value: 100}]
                                                        }
                                                    }}
                                                    arc={{
                                                        colorArray: ['#5BE12C', '#EA4228'],
                                                        subArcs: [{limit: 10}, {limit: 30}, {}, {}, {}],
                                                        padding: 0.02,
                                                        width: 0.3
                                                    }}
                                                    pointer={{
                                                        elastic: true, animationDelay: 2
                                                    }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bf_padding">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiggotMeter;