import React, {useEffect, useState} from "react";
import {
    AddonEdit, AdminRecruitmentPanel, DiscordLink, FiggotMeter, Toggler, UnAuthorized, VodEdit
} from "../../components";
import axios from "axios";
import {ClipLoader} from "react-spinners";
import GaugeComponent from "react-gauge-component";

const Admin = () => {
    const [isLogged, setIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let config = {
            method: 'post', url: '/admin/heartbeat',
        };

        const fetchData = async () => {
            try {
                const response = await axios.request(config);
                if (response.data.message === "Logged in!") {
                    setIsLogged(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                // Introduce artificial delay
                setTimeout(() => {
                    setIsLoading(false);
                }, 1500);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return (// Change height based on final admin panel size
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "20vh"}}>
                <ClipLoader size={50} color={"#123abc"} loading={isLoading}/>
            </div>);
    }

    return (<>
        {!isLogged ? <UnAuthorized/> : <div id="dialog_container">
            {/*Toggle for slide hide/show state*/}
            <div id="dialog">
                <div id="guild_content">
                    <div className="box">
                        <div className="header">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bh_padding">
                                    SpreadSheet Visibility Status
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bc_padding">

                                    <div className="message">
                                        <Toggler style={{left: "45%"}}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="left_image"></div>
                            <div className="right_image"></div>
                            <div className="mid_image">
                                <div></div>
                            </div>
                            <div className="inner_content">
                                <div className="bf_padding">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Update discord link*/}
            <DiscordLink/>

            {/*Recruitment Status Update*/}
            <AdminRecruitmentPanel/>

            {/*Drama links edit*/}
            <VodEdit/>

            {/*Addon guides Edit*/}
            <AddonEdit/>

            {/*Figgot meter levels*/}
            <FiggotMeter/>
        </div>}
    </>);
};

export default Admin;
