import React, {useEffect, useState} from "react";
import axios from "axios";

const DiscordLink = () => {
    const [newLink, setNewLink] = useState("");

    useEffect(() => {

    }, []);

    const handleApply = () => {
        // Perform validation or additional logic as needed before sending the update
        if (newLink.trim() === "" || !newLink.startsWith("https://discord.gg/")) {
            alert("Please enter a valid Discord link.");
            return;
        }

        let config = {
            method: 'post', url: '/admin/discord', headers: {
                'Content-Type': 'application/json',
            }, data: {
                "discord_link": newLink,
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.data.message === "Discord link set") {
                    setNewLink("");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (<div id="dialog">
            <div id="guild_content">
                <div className="box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bh_padding">
                                Update discord link
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bc_padding">
                                <div className="message">
                                    <input
                                        type="text"
                                        value={newLink}
                                        onChange={(e) => setNewLink(e.target.value)}
                                        placeholder="Enter new Discord link"
                                        style={{marginRight: '10px'}}
                                    />
                                    <button className="btn" onClick={handleApply} style={{
                                        width: "22%", marginLeft: "40%"
                                    }}>Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bf_padding">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default DiscordLink;