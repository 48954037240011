import React, { useState } from 'react';
import './Sliderx.css';

const Sliderx = ({ clips }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container">
      <div className="video-container">
        <iframe
          src={`https://clips.twitch.tv/embed?clip=${clips[currentIndex].video_url}&parent=${window.location.hostname}&autoplay=true`}
          title={clips[currentIndex].title}
          width="1000"
          height="620"
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div>

      <div className="background-strip">
        <div className="bullets">
          {clips.map((_, index) => (
            <span
              key={index}
              className={`bullet ${index === currentIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sliderx;