import React from "react";
import ReactMarkdown from 'react-markdown'

function LinkRenderer(props) {
    return (<a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>);
}

const MarkdownRenderer = ({children}) => {
    return (<ReactMarkdown components={{a: LinkRenderer}}>{children}</ReactMarkdown>);
};

const AddonFrame = ({title, content}) => {

    return (<div id="dialog_container">
        <div id="dialog">
            <div id="guild_content">
                <div className="box">
                    <div className="header">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bh_padding">
                                {title}
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content" style={{ height: "375px" }}>
                            <div className="bc_padding">
                                <div className="message">
                                    <MarkdownRenderer children={content}>
                                        {/*{content}*/}
                                    </MarkdownRenderer>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="left_image"></div>
                        <div className="right_image"></div>
                        <div className="mid_image">
                            <div></div>
                        </div>
                        <div className="inner_content">
                            <div className="bf_padding">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default AddonFrame;