import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Sliderx} from "../../components/index.js";

const Drama = () => {
    const [clips, setClips] = useState(null);

    useEffect(() => {

        let config = {
            method: 'post', url: '/pub/drama'
        };

        axios.request(config)
            .then((response) => {
                let drama = response.data.drama;
                drama.forEach(item => {
                    const urlParts = item.video_url.split('/clip/');
                    item.video_url = urlParts.length > 1 ? urlParts[1] : item.video_url;
                })
                setClips(drama);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (<>
            {clips && <Sliderx clips={clips}/>}
        </>);

};

export default Drama;
