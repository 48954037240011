import React, {useEffect, useState} from "react";
import axios from "axios";
import './AdminRecruitmentPanel.css';
import dk from "../../assets/class_images/dk.png";
import druid from "../../assets/class_images/druid.png";
import hunter from "../../assets/class_images/hunter.png";
import mage from "../../assets/class_images/mage.png";
import paladin from "../../assets/class_images/paladin.png";
import priest from "../../assets/class_images/priest.png";
import rogue from "../../assets/class_images/rogue.png";
import shaman from "../../assets/class_images/shaman.png";
import warlock from "../../assets/class_images/warlock.png";
import warrior from "../../assets/class_images/warrior.png";

const classes = [{name: "Death Knight", img: dk}, {name: "Druid", img: druid}, {
    name: "Hunter", img: hunter
}, {name: "Mage", img: mage}, {name: "Paladin", img: paladin}, {name: "Priest", img: priest}, {
    name: "Rogue", img: rogue
}, {name: "Shaman", img: shaman}, {name: "Warlock", img: warlock}, {name: "Warrior", img: warrior},];

const AdminRecruitmentPanel = () => {
    const [recruitment, setRecruitment] = useState([]);
    const [updatedStatuses, setUpdatedStatuses] = useState({});

    useEffect(() => {
        axios.post('/pub/recruitment')
            .then(response => setRecruitment(response.data))
            .catch(error => console.log(error));
    }, []);

    const handleStatusChange = (e, className) => {
        setUpdatedStatuses({...updatedStatuses, [className]: e.target.value.toUpperCase()});
    };

    const handleSubmit = async () => {
        const changedStatuses = recruitment
            .map((rec, index) => {
                const className = classes[index].name;
                if (updatedStatuses[className] && updatedStatuses[className] !== rec.status) {
                    return {...rec, status: updatedStatuses[className]};
                }
                return null;
            })
            .filter(rec => rec !== null);

        try {
            let config = {
                method: 'post', url: '/admin/recruitment', headers: {
                    'Content-Type': 'application/json',
                }, data: updatedStatuses,
            };

            axios.request(config)
                .then((response) => {
                    if (response.status === 200 && response.data.message === "Recruitment updated successfully") {
                        setRecruitment(prevRecruitment => prevRecruitment.map((rec, index) => {
                            const className = classes[index].name;
                            return changedStatuses.find(changedRec => changedRec.class === rec.class) || rec;
                        }));
                        setUpdatedStatuses({});
                    } else {
                        alert('Failed to update statuses');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });


        } catch (error) {
            console.error('Error updating statuses:', error);
            alert('Error updating statuses');
        }
    };

    return (<div id="left_column" style={{maxWidth: "100%"}}>
        <div className="block recruitment" id="block_2861924">
            <div className="side_box">
                <div className="header">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content">
                        <div className="sh_padding">Edit Recruitment</div>
                    </div>
                </div>
                <div className="content">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content">
                        <div className="sc_padding">
                            <div className="message">
                                <div className="recruit-grid" style={{
                                    display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem"
                                }}>
                                    {recruitment.map((rec, index) => (
                                        <div key={classes[index].name} className="recruit" style={{
                                            display: "flex", alignItems: "center", gap: "0.5rem"
                                        }}>
                                            <div className="recruit-type" title={classes[index].name}>
                                                <img src={classes[index].img}
                                                     alt={`Image of a ${classes[index].name}`}/>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder={rec.status}
                                                value={updatedStatuses[classes[index].name] || ""}
                                                onChange={(e) => handleStatusChange(e, classes[index].name)}
                                                title={classes[index].name}
                                            />
                                        </div>))}
                                </div>

                                <button className="btn" onClick={handleSubmit} style={{
                                    outline: "none", width: "22%", marginLeft: "40%", height: "36px"
                                }}>Update All
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content"></div>
                </div>
            </div>
        </div>
    </div>);
};

export default AdminRecruitmentPanel;
