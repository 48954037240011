import React from "react";
import {DateBlock, RecruitmentPanel} from "../../components";
import "./Home.css";

const Home = () => {
    const twitch_player_css = {
        width: "78%",
        marginTop: "-61.55%",
        marginLeft: "22%",
        height: "589px",
        marginBottom: "2%",
    }

    return (<div id="main_content_container">
        <DateBlock/>
        <RecruitmentPanel/>

        <iframe
            src={`https://player.twitch.tv/?darkpopout&channel=artn_tv&autoplay=true&muted=false&parent=${window.location.hostname}&theme=dark`}
            style={twitch_player_css}
            frameBorder="0"
            scrolling="no"
            allowFullScreen="true">
        </iframe>

        <iframe
            src={`https://www.twitch.tv/embed/artn_tv/chat?darkpopout&parent=${window.location.hostname}&theme=dark`}
            height="500"
            width="99%"
            style={{height: "500px", width: "99%", position: "relative", left: "2.3px", backgroundColor: "#000", opacity: "0.8"}}
            frameBorder="0"
            scrolling="no"
            allowFullScreen="true">
        </iframe>

    </div>);
}

export default Home;