import React, {useEffect, useState} from "react";
import axios from "axios";
import {ComingSoon} from "../../components/index.js";
import ConfettiExplosion from "react-confetti-explosion";
import {ClipLoader} from "react-spinners";

const Tactics = () => {
    const [tactics, setTactics] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [browser, setBrowser] = useState(null);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("chrome") > -1 && userAgent.indexOf("edg") === -1) {
            setBrowser("chrome");
        } else if (userAgent.indexOf("firefox") > -1) {
            setBrowser("firefox");
        } else {
            setBrowser("other");
        }
    }, []);

    useEffect(() => {
        let config = {
            method: 'post',
            url: '/pub/tactics',
        };

        axios.request(config)
            .then((response) => {
                if (response.data.assignments_url) {
                    setTactics(response.data.assignments_url);
                } else {
                    setTactics("hidden");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, []);

    const LoadingPlaceholder = () => (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "20vh"}}>
            <ClipLoader size={50} color={"#123abc"} loading={isLoading}/>
        </div>
    );

    return (
        <div id={"main_content_container"}>
            {isLoading ? (
                <LoadingPlaceholder/>
            ) : (
                <>
                    {tactics === "hidden" || tactics === null ? (
                        <ComingSoon/>
                    ) : (
                        <>
                            <ConfettiExplosion
                                particleCount={200}
                                duration={3000}
                                width={1000}
                                height={1000}
                                style={{marginLeft: "230px"}}
                            />
                            <iframe
                                src={tactics}
                                height="650px"
                                allowFullScreen={true}
                                style={{
                                    backgroundColor: '#000',
                                    border: 'none',
                                    marginLeft: browser === "chrome" ? "17%" : "23%",
                                    maxWidth: browser === "chrome" ? "68.6%" : "51.75%",
                                    width: "100%",
                                    overflow: "hidden",
                                    scrollbarWidth: "none",
                                    msOverflowStyle: "none",
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default Tactics;
