import React, {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './assets/css/structure.css'
import './assets/css/layout.css'


ReactDOM.createRoot(document.getElementById('root')).render(
    <App/>
)