import React, {useEffect, useState} from "react";
import "./Toggler.css";
import axios from "axios";

const Toggler = ({style}) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        // Fetch initial status from the server
        axios.post('/admin/assignments/status')
            .then((response) => {
                let spreadsheet_status = response.data.message;
                if (spreadsheet_status === "hidden") {
                    setIsChecked(false);
                } else {
                    setIsChecked(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const onChange = () => {
        setIsChecked(!isChecked);
        let config = {
            method: 'post',
            url: '/admin/assignments',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "is_hidden": isChecked
            })
        };

        axios.request(config)
            .then((response) => {
                if (response.data.message === "Assignments page status updated!") {

                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <label className="switch" style={style}>
            <input type="checkbox" checked={isChecked} onChange={() => onChange()}/>
            <span className="slider round"></span>
        </label>
    );
}

export default Toggler;