import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {FaEdit, FaTimes, FaCheck, FaPlus, FaTrashAlt, FaPencilAlt, FaEye, FaEyeSlash} from "react-icons/fa";

const VodEdit = () => {
    const initialVodList = [{id: 1, title: "Title 1", url: "asdasd", visible: true}, {
        id: 2, title: "Title 2", url: "123123", visible: false
    }, {id: 3, title: "Title 3", url: "asdasd123123", visible: true}];
    const [vodList, setVodList] = useState(initialVodList);
    const [newTitle, setNewTitle] = useState("");
    const [newUrl, setNewUrl] = useState("");
    const [editingIndex, setEditingIndex] = useState(-1); // -1 means not editing any VOD
    const [originalVodList, setOriginalVodList] = useState([...initialVodList]); // Store original values
    const [initialVisibility, setInitialVisibility] = useState(initialVodList.map(vod => vod.visible));

    const StyledIconButton = styled.button`
        background-color: #3f51b5; /* Dark blue background color */
        color: white; /* Text color */
        border: none;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #303f9f; /* Darker shade for hover */
        }

        &:disabled {
            background-color: #ddd; /* Grey background color when disabled */
            color: #888; /* Grey text color when disabled */
            cursor: not-allowed; /* Not allowed cursor when disabled */
        }
    `;

    const handleEditClick = (index) => {
        if (editingIndex === index) {
            setEditingIndex(-1);
        } else {
            setEditingIndex(index);
        }
    };

    const handleSaveClick = (index) => {
        // Check for duplicates in the title and URL
        const editedVod = vodList[index];
        const isDuplicate = vodList.some((vod, i) => (vod.title === editedVod.title || vod.url === editedVod.url) && i !== index);

        if (isDuplicate) {
            alert("Duplicate title or URL found. Please ensure all titles and URLs are unique.");
            return;
        }

        // Save changes to backend/API if needed
        console.log(`Saving VOD at index ${index}:`, vodList[index]);
        setEditingIndex(-1);
    };

    const handleCancelClick = (index) => {
        // Revert changes when cancelling edit
        const updatedVodList = [...vodList];
        updatedVodList[index] = {...originalVodList[index]}; // Restore original values
        setVodList(updatedVodList);
        setEditingIndex(-1);
    };

    const addVod = () => {

        if (newTitle.trim() !== "" && newUrl.trim() !== "") {
            const isDuplicate = vodList.some(link => link.title === newTitle || link.url === newUrl);
            if (isDuplicate) {
                alert("You can't have duplicate vod title/urls")
                setNewTitle("");
                setNewUrl("");
                return;
            }

            const newId = vodList.length + 1;
            setVodList([...vodList, {id: newId, title: newTitle, url: newUrl, visible: false}]);
            setOriginalVodList([...originalVodList, {id: newId, title: newTitle, url: newUrl, visible: false}]); // Update original list
            setInitialVisibility([...initialVisibility, false]);
            setNewTitle("");
            setNewUrl("");
        }
    };

    const deleteVod = (index) => {
        const updatedVodList = vodList.filter((vod) => vod.id !== index);
        const updatedOriginalVodList = originalVodList.filter((vod) => vod.id !== index); // Update original list
        const updatedInitialVisibility = initialVisibility.filter((vis, idx) => idx !== index);
        setVodList(updatedVodList);
        setOriginalVodList(updatedOriginalVodList);
        setInitialVisibility(updatedInitialVisibility);
        setEditingIndex(-1);
    };

    const toggleVisibility = async (index) => {
        if (editingIndex === -1) {
            const updatedVodList = [...vodList];
            updatedVodList[index].visible = !updatedVodList[index].visible;
            setVodList(updatedVodList);
            // Send request to update visibility on server
            try {
                // Simulating backend update
                console.log(`Toggling visibility for VOD at index ${index} to ${updatedVodList[index].visible}`);
                // Example axios call:
                // const response = await axios.put(`/api/update_visibility/${index}`, { visible: updatedVodList[index].visible });
            } catch (error) {
                console.error("Error updating visibility:", error);
            }
        }
    };

    return (<div id="dialog">
        <div id="guild_content">
            <div className="box">
                <div className="header">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content">
                        <div className="bh_padding">Edit Vods</div>
                    </div>
                </div>
                <div className="content">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content">
                        <div className="bc_padding">
                            <div className="message">
                                {vodList.map((vod, index) => (<div key={vod.id} style={{marginBottom: "10px"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <input
                                            type="text"
                                            value={vod.title}
                                            onChange={(e) => {
                                                const updatedVodList = [...vodList];
                                                updatedVodList[index] = {
                                                    ...updatedVodList[index], title: e.target.value
                                                };
                                                setVodList(updatedVodList);
                                            }}
                                            disabled={index !== editingIndex}
                                            style={{marginRight: "10px", width: "30%"}}
                                        />
                                        <input
                                            type="text"
                                            value={vod.url}
                                            onChange={(e) => {
                                                const updatedVodList = [...vodList];
                                                updatedVodList[index] = {
                                                    ...updatedVodList[index], url: e.target.value
                                                };
                                                setVodList(updatedVodList);
                                            }}
                                            disabled={index !== editingIndex}
                                            style={{marginRight: "10px", width: "30%"}}
                                        />
                                        {index === editingIndex ? (<>
                                            <StyledIconButton onClick={() => handleSaveClick(index)}>
                                                <FaCheck/>
                                            </StyledIconButton>
                                            <StyledIconButton onClick={() => handleCancelClick(index)}>
                                                <FaTimes/>
                                            </StyledIconButton>
                                        </>) : (<StyledIconButton onClick={() => handleEditClick(index)}
                                                                  disabled={editingIndex !== -1}>
                                            <FaPencilAlt/>
                                        </StyledIconButton>)}
                                        <StyledIconButton onClick={() => deleteVod(vod.id)}
                                                          disabled={editingIndex !== -1}>
                                            <FaTrashAlt/>
                                        </StyledIconButton>
                                        <StyledIconButton onClick={() => toggleVisibility(index)}
                                                          disabled={editingIndex !== -1}>
                                            {vod.visible ? <FaEye/> : <FaEyeSlash/>}
                                        </StyledIconButton>
                                    </div>
                                </div>))}
                                <div style={{marginTop: "10px"}}>
                                    <input
                                        type="text"
                                        value={newTitle}
                                        onChange={(e) => setNewTitle(e.target.value)}
                                        placeholder="Enter new title"
                                        style={{marginRight: "10px", width: "30%"}}
                                        disabled={editingIndex !== -1}
                                    />
                                    <input
                                        type="text"
                                        value={newUrl}
                                        onChange={(e) => setNewUrl(e.target.value)}
                                        placeholder="Enter new URL"
                                        style={{marginRight: "10px", width: "30%"}}
                                        disabled={editingIndex !== -1}
                                    />
                                    <StyledIconButton onClick={addVod} disabled={editingIndex !== -1}>
                                        <FaPlus/>
                                    </StyledIconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="left_image"></div>
                    <div className="right_image"></div>
                    <div className="mid_image">
                        <div></div>
                    </div>
                    <div className="inner_content">
                        <div className="bf_padding"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export default VodEdit;